// src/Components/Layout.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header/Header';
// Ensure this file exists and is properly styled

const Layout = () => {
  return (
    <div className="layout">
      <Header />
      <main className="main-content">
        <Outlet />
      </main>
    </div>
  );
};

export default Layout;
