import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './AddProduct.css';

const AddProduct = ({url}) => {
  const [product, setProduct] = useState({
    name: '',
    description: '',
    price: '',
    category: '',
    color: '',
    images: []
  });

  const categories = ['New in', 'Women', 'Men', 'Kids', 'Fabric'];
  const colors = ['Red', 'Green', 'Blue', 'Yellow', 'Black', 'Gold', 'Ivory', 'Orange', 'Grey', 'Tan'];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  };

  const handleCategoryChange = (category) => {
    setProduct({ ...product, category });
  };

  const handleColorChange = (color) => {
    setProduct({ ...product, color });
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 3) {
      toast.error('You can only upload up to 3 images.');
      return;
    }
    setProduct({ ...product, images: files });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formData = new FormData();
    formData.append('name', product.name);
    formData.append('description', product.description);
    formData.append('price', product.price);
    formData.append('category', product.category);
    formData.append('color', product.color);
  
    // Append images to FormData
    product.images.forEach((image) => {
      formData.append('images', image);
    });

    try {
      const response = await axios.post(`${url}/api/products/add`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success('Product added successfully!');
      setProduct({
        name: '',
        description: '',
        price: '',
        category: '',
        color: '',
        images: []
      });
    } catch (error) {
      toast.error(error.response ? error.response.data.message : error.message);
    }
  };

  return (
    <div className="product-container">
      <ToastContainer />
      <section className="add-product-container">
        <div className="add-product-page">
          <h1>Add Product</h1>
          <form onSubmit={handleSubmit} className="product-form">
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={product.name}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="price">Price</label>
              <input
                type="number"
                id="price"
                name="price"
                value={product.price}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="description">Description</label>
              <textarea
                id="description"
                name="description"
                value={product.description}
                onChange={handleInputChange}
                required
              ></textarea>
            </div>
            <div className="form-group">
              <label>Category</label>
              <div className="grid">
                {categories.map((category) => (
                  <div
                    key={category}
                    className={`grid-item ${product.category === category ? 'selected' : ''}`}
                    onClick={() => handleCategoryChange(category)}
                  >
                    {category}
                  </div>
                ))}
              </div>
            </div>
            <div className="form-group">
              <label>Color</label>
              <div className="grid">
                {colors.map((color) => (
                  <div
                    key={color}
                    className={`grid-item ${product.color === color ? 'selected' : ''}`}
                    onClick={() => handleColorChange(color)}
                  >
                    {color}
                  </div>
                ))}
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="images">Images</label>
              <input
                type="file"
                id="images"
                name="images"
                accept="image/*"
                multiple
                onChange={handleImageChange}
              />
              <p style={{ fontSize: '14px', color: '#6c757d' }}>You can upload up to 3 images.</p>
            </div>
            <button type="submit" className="submit-button">Add Product</button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default AddProduct;
