// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './Components/Context/AuthContext';
import Layout from './Components/Layout';
import Summary from './Components/Summary/Summary';
import AddProducts from './Components/AddProduct/AddProduct';
import ManageProducts from './Components/ManageProducts/ManageProducts';
import ManageOrders from './Components/ManageOrders/ManageOrders';
import Login from './Components/Login/Login';
import ProtectedRoute from './Components/ProtectedRoute/ProtectedRoute';
import BannerManagement from './Components/BannerManagement/BannerManagement';
// Import other components as needed

const App = () => {
  const url = "https://lecotrus.com";
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Layout />}>
            <Route
              path="summary"
              element={
                <ProtectedRoute>
                  <Summary url={url} />
                </ProtectedRoute>
              }
            />
            <Route
              path="add-products"
              element={
                <ProtectedRoute>
                  <AddProducts url={url} />
                </ProtectedRoute>
              }
            />
            <Route
              path="manage-products"
              element={
                <ProtectedRoute>
                  <ManageProducts url={url} />
                </ProtectedRoute>
              }
            />
            <Route
              path="manage-orders"
              element={
                <ProtectedRoute>
                  <ManageOrders url={url} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manage-banner-image"
              element={
                <ProtectedRoute>
                  <BannerManagement url={url} />
                </ProtectedRoute>
              }
            />
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
