import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './EditProductModal.css';

const EditProductModal = ({ product, onClose, onProductUpdate ,url }) => {
  const [updatedProduct, setUpdatedProduct] = useState({ ...product });
  const [images, setImages] = useState([]); // State for handling new images

  const colors = ["Red", "Blue", "Green", "Yellow", "Black", "White"]; // Example color options
  const categories = ["NewIn", "Women", "Men", "Kids", "Fabric"]; // Example category options

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedProduct({ ...updatedProduct, [name]: value });
  };

  const handleRadioChange = (e) => {
    setUpdatedProduct({ ...updatedProduct, inStock: e.target.value === 'true' });
  };

  const handleImageChange = (e) => {
    setImages(e.target.files); 
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('name', updatedProduct.name);
    formData.append('description', updatedProduct.description);
    formData.append('price', updatedProduct.price);
    formData.append('category', updatedProduct.category);
    formData.append('color', updatedProduct.color);
    formData.append('inStock', updatedProduct.inStock); 

    for (const file of images) {
      formData.append('images', file);
    }

    try {
      const response = await axios.put(`${url}/api/products/update/${product.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      onProductUpdate(response.data);
      toast.success('Product updated successfully');
    } catch (error) {
      console.error('Failed to update product:', error);
      toast.error('Failed to update product');
    }
  };

  return (
    <div className="modall">
      <div className="modal-Content">
        <span className="close" onClick={onClose}>&times;</span>
        <h2>Edit Product</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={updatedProduct.name}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="price">Price</label>
            <input
              type="number"
              id="price"
              name="price"
              value={updatedProduct.price}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="category">Category</label>
            <select
              id="category"
              name="category"
              value={updatedProduct.category}
              onChange={handleInputChange}
              required
            >
              {categories.map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="color">Color</label>
            <select
              id="color"
              name="color"
              value={updatedProduct.color}
              onChange={handleInputChange}
              required
            >
              {colors.map((color) => (
                <option key={color} value={color}>
                  {color}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="description">Description</label>
            <textarea
              id="description"
              name="description"
              value={updatedProduct.description}
              onChange={handleInputChange}
              required
            ></textarea>
          </div>
          <div className="form-group">
            <label>Stock Status</label>
            <div>
              <label>
                <input
                  type="radio"
                  name="inStock"
                  value="true"
                  checked={updatedProduct.inStock === true}
                  onChange={handleRadioChange}
                />
                In stock
              </label>
              <label>
                <input
                  type="radio"
                  name="inStock"
                  value="false"
                  checked={updatedProduct.inStock === false}
                  onChange={handleRadioChange}
                />
                Out of Stock
              </label>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="images">Images</label>
            <input
              type="file"
              id="images"
              name="images"
              accept="image/*"
              multiple
              onChange={handleImageChange}
            />
          </div>
          <button type="submit" className="submit-button">Update Product</button>
        </form>
      </div>
    </div>
  );
};

export default EditProductModal;
