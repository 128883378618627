import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Summary.css';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const Summary = ({url}) => {
  const [summaryData, setSummaryData] = useState({
    totalSale: '₹0',
    products: '0',
    orders: '0',
    paidOrders: '0',
    unpaidOrders: '0',
    totalUsers: '0',
  });

  const [salesData, setSalesData] = useState([]);
  const [ordersData, setOrdersData] = useState([]);

  useEffect(() => {
    const fetchSummary = async () => {
      try {
        const response = await axios.get(`${url}/api/summary`);
        const data = response.data;
        setSummaryData({
          totalSale: `₹${data.totalSale}`,
          products: data.products.toString(),
          orders: data.orders.toString(),
          paidOrders: data.paidOrders.toString(),
          unpaidOrders: data.unpaidOrders.toString(),
          totalUsers: data.totalUsers.toString(),
        });
        setSalesData(data.salesData); // Assuming sales data is an array of {date, sales}
        setOrdersData(data.ordersData); // Assuming orders data is an array of {date, orders}
      } catch (error) {
        console.error('Error fetching summary data:', error);
      }
    };

    fetchSummary();
  }, []);

  return (
    <div className='summary-main'>
      <div className="summary-page">
        <h1 style={{ textAlign: 'center' }}>Summary</h1>
        <div className="summary-grid">
          {Object.entries(summaryData).map(([key, value], index) => (
            <div key={index} className="summary-card">
              <h2>{key.replace(/([A-Z])/g, ' $1').trim()}</h2>
              <p>{value}</p>
            </div>
          ))}
        </div>

        <div className="summary-charts">
          <h2 style={{ textAlign: 'center' }}>Sales Over Time</h2>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={salesData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="sales" stroke="#8884d8" activeDot={{ r: 8 }} />
            </LineChart>
          </ResponsiveContainer>

          <h2 style={{ textAlign: 'center' }}>Orders Over Time</h2>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={ordersData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="orders" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default Summary;
