import React, { useState, useEffect } from 'react';
import './BannerManagement.css';

const BannerManagement = ({ url }) => {
  const [banners, setBanners] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadStatus, setUploadStatus] = useState(''); // To track the upload status
  const [bannerCategory, setBannerCategory] = useState(''); // Track selected banner category

  // Fetch banners from the backend when the component mounts
  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await fetch(`${url}/api/banners`); // Adjusted endpoint to match the backend api
        if (response.ok) {
          const data = await response.json();
          setBanners(data);
        } else {
          console.error('Failed to fetch banners');
        }
      } catch (error) {
        console.error('Error fetching banners:', error);
      }
    };

    fetchBanners();
  }, [url]); // Corrected dependency to avoid infinite re-fetching

  const handleFileSelection = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files); // Store selected files in state
  };

  const handleImageUpload = async () => {
    if (selectedFiles.length === 0) {
      alert('Please select a file first!');
      return;
    }
    if (!bannerCategory) {
      alert('Please select a category!');
      return;
    }

    // Set upload status to "Uploading"
    setUploadStatus('Uploading...');

    const formData = new FormData();
    selectedFiles.forEach((file) => formData.append('banner', file));
    formData.append('category', bannerCategory); // Add category to form data

    try {
      const response = await fetch(`${url}/api/banners/upload`, { // Adjusted endpoint to match the backend api
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const newBanner = await response.json();
        setBanners([...banners, newBanner.banner]); // Add the new banner to the list
        setUploadStatus('Upload successful!');
        setSelectedFiles([]); // Clear selected files after successful upload
      } else {
        setUploadStatus('Failed to upload banner');
        console.error('Failed to upload banner');
      }
    } catch (error) {
      setUploadStatus('Error uploading banner');
      console.error('Error uploading banner:', error);
    }
  };

  // Handle "Enter" key to trigger the upload
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleImageUpload();
    }
  };

  const handleImagePreview = (imageUrl) => {
    setPreviewImage(imageUrl);
  };

  const handleDeleteBanner = async (id, imageUrl) => {
    try {
      const response = await fetch(`${url}/api/banners/delete/${id}`, { // Adjusted endpoint to match the backend api
        method: 'DELETE',
      });

      if (response.ok) {
        setBanners(banners.filter((banner) => banner._id !== id)); // Assuming each banner has an _id property
        if (previewImage === imageUrl) {
          setPreviewImage(null);
        }
      } else {
        console.error('Failed to delete banner');
      }
    } catch (error) {
      console.error('Error deleting banner:', error);
    }
  };

  const handleClosePreview = () => {
    setPreviewImage(null); // Close the image preview
  };

  const handleCategoryChange = (e) => {
    setBannerCategory(e.target.value);
  };

  return (
    <div className="banner-management-container">
      <h2>Manage Banner Images</h2>

      <div className="upload-section">
        <input
          type="file"
          onChange={handleFileSelection}
          accept="image/*"
          multiple
          onKeyPress={handleKeyPress} // Trigger upload on Enter
        />

        <select
          value={bannerCategory}
          onChange={handleCategoryChange}
          className="category-dropdown"
        >
          <option value="" disabled>Select Banner Category</option>
          <option value="slider">Slider Banner</option>
          <option value="mobile-silder">Mobile Slider Banner</option>
          <option value="home">Home Page Banner</option>
          <option value="mobile-home">Mobile Home Banner</option>
        </select>

        <button
          type="button"
          onClick={handleImageUpload}
          className="upload-button"
        >
          Upload Image(s)
        </button>
        {uploadStatus && <p className="upload-status">{uploadStatus}</p>}
      </div>

      <div className="banner-list">
        {banners.length === 0 && <p>No banners uploaded yet.</p>}
        {banners.map((banner) => (
          <div className="banner-item" key={banner._id}>
            <img
              src={`${url}/api/images/${banner.imagePath}`} // Adjusted URL format for image
              alt={`Banner ${banner._id}`}
              onClick={() => handleImagePreview(`${url}/api/images/${banner.imagePath}`)} // Adjusted URL format for preview
            />
            <div className="banner-info">
              <p className="banner-category">{banner.category}</p> {/* Display category */}
              <div className="banner-controls">
                <button
                  className="delete-btn"
                  onClick={() => handleDeleteBanner(banner._id, banner.imagePath)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="preview-section">
        {previewImage && (
          <div className="preview-wrapper">
            <button className="close-preview-btn" onClick={handleClosePreview}>
              ✕
            </button>
            <img
              src={previewImage}
              alt="Banner Preview"
              className="banner-preview"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default BannerManagement;
