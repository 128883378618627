// src/Components/Header/Header.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie, faPlus, faEdit, faClipboardList ,faImages } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.png';
import './Header.css'; // Ensure this file is correctly named

const Header = () => {
  return (
    <div className="header">
      <header className="header-container">
        <img className="logo" src={logo} alt="Logo" />
        {/* <div className="header-icons">
          <FontAwesomeIcon icon={faBell} className="icon" />
          <FontAwesomeIcon icon={faUser} className="icon" />
        </div> */}
      </header>
      <nav className="nav">
        <ul>
          <li>
            <Link to="/summary" className="nav-link">
              <FontAwesomeIcon icon={faChartPie} className="nav-icon" />
              Summary
            </Link>
          </li>
          <li>
            <Link to="/add-products" className="nav-link">
              <FontAwesomeIcon icon={faPlus} className="nav-icon" />
              Add Products
            </Link>
          </li>
          <li>
            <Link to="/manage-products" className="nav-link">
              <FontAwesomeIcon icon={faEdit} className="nav-icon" />
              Manage Products
            </Link>
          </li>
          <li>
            <Link to="/manage-orders" className="nav-link">
              <FontAwesomeIcon icon={faClipboardList} className="nav-icon" />
              Manage Orders
            </Link>
          </li>
          <li>
            <Link to="/manage-banner-image" className="nav-link">
              <FontAwesomeIcon icon={faImages} className="nav-icon" />
              Manage Banner Image
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Header;
