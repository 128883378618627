import React, { useState, useEffect } from 'react';
import './ManageOrders.css';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const ManageOrders = ({ url }) => {
    const [orders, setOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [isModalOpens, setisModalOpens] = useState(false);
    const [newStatus, setNewStatus] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await fetch(`${url}/api/orders`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setOrders(data);
            } catch (error) {
                console.error('Error fetching orders:', error);
            }
        };

        fetchOrders();
    }, [url]);

    const handleOrderClick = (order) => {
        setSelectedOrder(order);
        setNewStatus(order.status);
        setisModalOpens(true);
    };

    const handleCloseModal = () => {
        setisModalOpens(false);
        setSelectedOrder(null);
    };

    const handleStatusChange = (e) => {
        setNewStatus(e.target.value);
    };

    const handleUpdateStatus = async () => {
        if (selectedOrder) {
            try {
                const response = await fetch(`${url}/api/orders/${selectedOrder._id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ status: newStatus }),
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                setOrders(orders.map(order =>
                    order._id === selectedOrder._id ? { ...order, status: newStatus } : order
                ));
                handleCloseModal();
            } catch (error) {
                console.error('Error updating order status:', error);
            }
        }
    };

    const handlePrint = async (order) => {
        try {
            const invoiceHTML = `
                <div style="padding: 5px; font-family: Arial, sans-serif; font-size: 6px; width:500px;">
                    <div  margin-bottom: 5px;">
                        <img src="your-logo-url-here.png" alt="Company Logo" style="width: 50px;" />
                        <div>
                            <h1 style="margin: 0; font-size: 8px;">Invoice</h1>
                            <p style="margin: 0;">Order ID: ${order._id}</p>
                            <p style="margin: 0;">Transaction ID: ${order.transactionId}</p>
                        </div>
                    </div>
                    <h3 style="margin: 0; font-size: 7px;">Customer Details</h3>
                    <p><strong>Name:</strong> ${order.userId?.name || 'Unknown Customer'}</p>
                    <p><strong>Date:</strong> ${new Date(order.orderDate).toLocaleDateString()}</p>
                    <h3 style="margin: 0; font-size: 7px;">Shipping Details</h3>
                    <p><strong>Address:</strong> ${order.shippingDetails.address}</p>
                    <p><strong>City:</strong> ${order.shippingDetails.city}</p>
                    <p><strong>State:</strong> ${order.shippingDetails.state}</p>
                    <p><strong>Zip Code:</strong> ${order.shippingDetails.zipCode}</p>
                    <p><strong>Country:</strong> ${order.shippingDetails.country}</p>
                    <p><strong>Payment Method:</strong> ${order.paymentMethod}</p>
                    <div style="text-align: right; margin-top: 5px;">
                        <h3 style="font-size: 7px;">Total Amount: $${order.total}</h3>
                    </div>
                    <p style="margin-top: 10px; text-align: center; font-size: 6px;">Thank you for your purchase!</p>
                </div>
            `;
    
            const pdf = new jsPDF('p', 'mm', 'a4');
            pdf.html(invoiceHTML, {
                callback: function (doc) {
                    // Adjust page margins and scale to fit everything on one page
                    doc.setPage(1);
                    doc.save(`Order_${order._id}.pdf`);
                },
                x: 5,
                y: 5,
                html2canvas: {
                    scale: 0.7 // scale down the content
                }
            });
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };
    
    

    const filteredOrders = orders.filter(order => 
        order._id.includes(searchTerm) ||
        order.transactionId?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        order.userId?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        order.status.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return (
        <div>
            <main className="main-contentt">
                <h1>Manage Orders</h1>
                <input
                    type="text"
                    placeholder="Search Orders"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <table className="table">
                    <thead>
                        <tr>
                            <th>Order ID</th>
                            <th>Customer Name</th>
                            <th>Date</th>
                            <th>Total Amount</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredOrders.map(order => (
                            <tr key={order._id}>
                                <td>{order._id}</td>
                                <td>{order.userId?.name || 'Unknown Customer'}</td>
                                <td>{new Date(order.orderDate).toLocaleDateString()}</td>
                                <td>${order.total}</td>
                                <td>{order.status}</td>
                                <td>
                                    <button onClick={() => handleOrderClick(order)}>View</button>
                                    <button onClick={() => handlePrint(order)}>Print</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {isModalOpens && selectedOrder && (
                    <div className="modal show">
                        <div className="modal-content" id={`order-${selectedOrder._id}`}>
                            <div className="modal-header">
                                <h2>Order Details</h2>
                                <button onClick={handleCloseModal}>Close</button>
                            </div>
                            <div className="modal-body">
                                <p><strong>Order ID:</strong> {selectedOrder._id}</p>
                                <p><strong>Transaction ID:</strong> {selectedOrder.transactionId}</p>
                                <p><strong>Customer Name:</strong> {selectedOrder.userId?.name}</p>
                                <p><strong>Date:</strong> {new Date(selectedOrder.orderDate).toLocaleDateString()}</p>
                                <p><strong>Total Amount:</strong> ${selectedOrder.total}</p>
                                <h3>Shipping Details</h3>
                                <p><strong>Address:</strong> {selectedOrder.shippingDetails.address}</p>
                                <p><strong>City:</strong> {selectedOrder.shippingDetails.city}</p>
                                <p><strong>State:</strong> {selectedOrder.shippingDetails.state}</p>
                                <p><strong>Zip Code:</strong> {selectedOrder.shippingDetails.zipCode}</p>
                                <p><strong>Country:</strong> {selectedOrder.shippingDetails.country}</p>
                                {/* <h3>Order Summary</h3>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Product</th>
                                            <th>Quantity</th>
                                            <th>Price</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedOrder.items.map(item => (
                                            <tr key={item.productId}>
                                                <td>{item.name}</td>
                                                <td>{item.quantity}</td>
                                                <td>${item.productId.price}</td>
                                                <td>${item.total}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table> */}
                                <p><strong>Payment Method:</strong> {selectedOrder.paymentMethod}</p>
                                <p><strong>Status:</strong></p>
                                <select value={newStatus} onChange={handleStatusChange}>
                                    <option value="Pending">Pending</option>
                                    <option value="Shipped">Shipped</option>
                                    <option value="Delivered">Delivered</option>
                                    <option value="Cancelled">Cancelled</option>
                                </select>
                            </div>
                            <div className="modal-footer">
                                <button onClick={handleUpdateStatus}>Update Status</button>
                            </div>
                        </div>
                    </div>
                )}
            </main>
        </div>
    );
};

export default ManageOrders;
