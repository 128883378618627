// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { FaTrashAlt, FaEdit } from 'react-icons/fa';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import './ManageProducts.css';
// import EditProductModal from './EditProductModal';

// const ManageProducts = ({url}) => {
//   const [products, setProducts] = useState([]);
//   const [selectedProduct, setSelectedProduct] = useState(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   useEffect(() => {
//     const fetchProducts = async () => {
//       try {
//         const response = await axios.get(`${url}/api/products/`);
//         setProducts(response.data);
//       } catch (error) {
//         console.error('Failed to fetch products:', error);
//         toast.error('Failed to fetch products');
//       }
//     };

//     fetchProducts();
//   }, []);

//   const deleteProduct = async (id) => {
//     try {
//       await axios.delete(`${url}/api/products/remove/${id}`);
//       setProducts(products.filter(product => product._id !== id));
//       toast.success('Product deleted successfully');
//     } catch (error) {
//       console.error('Failed to delete product:', error);
//       toast.error('Failed to delete product');
//     }
//   };

//   const openModal = (product) => {
//     setSelectedProduct(product);
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//     setSelectedProduct(null);
//   };

//   const handleProductUpdate = (updatedProduct) => {
//     setProducts(products.map(product => (product._id === updatedProduct._id ? updatedProduct : product)));
//     closeModal();
//   };

//   return (
//     <div className="manage-products-container">
//       <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '30px', fontSize: "20px" }}>
//         <h1 style={{ textAlign: "center" }}>Manage Products</h1>
//       </div>

//       <table>
//         <thead>
//           <tr>
//             <th>ID</th>
//             <th>Image</th>
//             <th>Name</th>
//             <th>Price</th>
//             <th>Category</th>
//             <th>Color</th>
//             <th>In Stock</th>
//             <th>Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {products.map((product) => (
//             <tr key={product._id} style={{ backgroundColor: product.inStock ? '#d4edda' : '#f8d7da' }}>
//               <td>{product.id}</td>
//               <td>
//                 <img src={`${url}/api/images/${product.images[0]}`} alt={product.name} className="product-image" />
//               </td>
//               <td>{product.name}</td>
//               <td>{product.price}</td>
//               <td>{product.category}</td>
//               <td>{product.color}</td>
//               <td>
//                 <p style={{ color: product.inStock ? 'green' : 'red', fontWeight: 'bold' }}>
//                   {product.inStock ? 'In Stock' : 'Out of Stock'}
//                 </p>
//               </td>
//               <td>
//                 <FaEdit
//                   onClick={() => openModal(product)}
//                   style={{ cursor: 'pointer', color: 'blue', fontSize: '15px', marginRight: '10px' }}
//                 />
//                 <FaTrashAlt
//                   onClick={() => deleteProduct(product.id)}
//                   style={{ cursor: 'pointer', color: 'black', fontSize: '15px' }}
//                 />
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//       <ToastContainer />

//       {isModalOpen && (
//         <EditProductModal
//           url={url}
//           product={selectedProduct}
//           onClose={closeModal}
//           onProductUpdate={handleProductUpdate}
//         />
//       )}
//     </div>
//   );
// };

// export default ManageProducts;




import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaTrashAlt, FaEdit, FaEye, FaEyeSlash } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ManageProducts.css';
import EditProductModal from './EditProductModal';

const ManageProducts = ({ url }) => {
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${url}/api/products/`);
        setProducts(response.data);
      } catch (error) {
        console.error('Failed to fetch products:', error);
        toast.error('Failed to fetch products');
      }
    };

    fetchProducts();
  }, [url]);

  const deleteProduct = async (id) => {
    try {
      await axios.delete(`${url}/api/products/remove/${id}`);
      setProducts(products.filter(product => product._id !== id));
      toast.success('Product deleted successfully');
    } catch (error) {
      console.error('Failed to delete product:', error);
      toast.error('Failed to delete product');
    }
  };

  const toggleVisibility = async (id) => {
    // Find the product using the 'id' field (not _id)
    const product = products.find(product => product.id === id);
  
    if (!product) {
      toast.error('Product not found');
      return;
    }
  
    try {
      // Adjust URL to send the correct product ID and action
      await axios.patch(`${url}/api/products/${product.isVisible ? 'hide' : 'unhide'}/${product.id}`);
      
      // Update the state locally after the request
      setProducts(products.map(prod => 
        prod.id === id ? { ...prod, isVisible: !prod.isVisible } : prod
      ));
      toast.success(`Product ${product.isVisible ? 'hidden' : 'unhidden'} successfully`);
    } catch (error) {
      console.error('Failed to toggle product visibility:', error);
      toast.error('Failed to toggle product visibility');
    }
  };
  
  
  
  

  const openModal = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };

  const handleProductUpdate = (updatedProduct) => {
    setProducts(products.map(product => (product._id === updatedProduct._id ? updatedProduct : product)));
    closeModal();
  };

  return (
    <div className="manage-products-container">
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '30px', fontSize: "20px" }}>
        <h1 style={{ textAlign: "center" }}>Manage Products</h1>
      </div>

      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Image</th>
            <th>Name</th>
            <th>Price</th>
            <th>Category</th>
            <th>Color</th>
            <th>In Stock</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <tr key={product._id} style={{ backgroundColor: product.inStock ? '#d4edda' : '#f8d7da' }}>
              <td>{product.id}</td>
              <td>
                <img src={`${url}/api/images/${product.images[0]}`} alt={product.name} className="product-image" />
              </td>
              <td>{product.name}</td>
              <td>{product.price}</td>
              <td>{product.category}</td>
              <td>{product.color}</td>
              <td>
                <p style={{ color: product.inStock ? 'green' : 'red', fontWeight: 'bold' }}>
                  {product.inStock ? 'In Stock' : 'Out of Stock'}
                </p>
              </td>
              <td>
                <FaEdit
                  onClick={() => openModal(product)}
                  style={{ cursor: 'pointer', color: 'blue', fontSize: '15px', marginRight: '10px' }}
                />
                <FaTrashAlt
                  onClick={() => deleteProduct(product.id)}
                  style={{ cursor: 'pointer', color: 'black', fontSize: '15px', marginRight: '10px' }}
                />
                {product.isVisible ? (
                  <FaEyeSlash
                    onClick={() => toggleVisibility(product.id)}
                    style={{ cursor: 'pointer', color: 'red', fontSize: '15px' }}
                  />
                ) : (
                  <FaEye
                    onClick={() => toggleVisibility(product.id)}
                    style={{ cursor: 'pointer', color: 'green', fontSize: '15px' }}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ToastContainer />

      {isModalOpen && (
        <EditProductModal
          url={url}
          product={selectedProduct}
          onClose={closeModal}
          onProductUpdate={handleProductUpdate}
        />
      )}
    </div>
  );
};

export default ManageProducts;
